@import "resources.scss";
.tcp-loader-overlay {
  @include pos(0 0 0 0, fixed, $z: $z9);
  background: rgba(#fff, .9);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  max-width: 100%;
  display: flex;
  justify-content: center;
  
  &-inner {
    border-radius: 4px;
    background: white;
    display: inline-block;
    padding: 65px 30px 40px;
    box-shadow: 0 0 15px 0 $shadow;
    width: 100%;
    max-width: 620px;
  }

  &-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    padding: 15px;
    width: 100%;

    &-paragraph {
      border-bottom: 1px solid $light-gray;
      padding: 10px 5px;
      &:last-child {
        border: 0;
      }
    }

    &-title {
      font-weight: 700;
      margin-bottom: 15px;
    }
  }

  &-icon {
    margin-bottom: 25px;

    .color-warning {
      color: $red;
    }
  }

  &-button {
    margin: auto;
    margin-top: 40px;
  }

  .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-content {
    @include make-box();
    padding: 50px;
  }
  .box-body {
    text-align: left;
  }
}
