@mixin sidebar-search-box {
    border: 1px solid $lighter-gray;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    cursor: pointer;
    background: white;

    @include tn(border-color);

    &-active {
        border-color: $body;
    }
}
