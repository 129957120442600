@import "resources.scss";
.loader-confirm {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        @include font-large;

        font-weight: 500;
        margin-bottom: 15px;
    }

    &-content {
        position: relative;
        margin-bottom: 55px;
    }

    &-text {
        &.is--hidden {
            opacity: 0;
        }
    }

    &-text-delayed {
        @include fade-in;
        position: absolute;
        top: 0;
        opacity: 1;
        width: 100%;
        left: 0;
    }

    &-footer {
        display: flex;
        width: 100%;
        align-items: center;

        .tcp-spinner {
            margin-right: 10px
        }
    }
}