@import "resources.scss";
.lp-warning-banner {
  border-radius: 4px;
  border: 1px solid $red;
  padding: 12px 12px 16px;
  background: white;
  margin: 0 20px 16px;
  font-weight: 400;
  display: flex;
  background: $rose;
  position: relative;

  &-info {
    border: 1px solid $blue;
    font-weight: normal;
  }

  &-icon {
    flex-shrink: 0;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    color: $red;
    font-size: 20px;
  }

  strong {
    font-weight: 500;
  }

  ul {
    li {
      margin-bottom: 4px;
      font-weight: normal;

      i {
        margin-right: 5px;
      }
    }

    &.error {
      i {
        color: $red;
        font-size: 14px;
      }
    }
  }

  &-close {
    float: right;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    
    i {
      font-size: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    margin: 0 0 16px;
  }
}
