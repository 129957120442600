@import "resources.scss";
.tcp-link {
  cursor: pointer;
  font-weight: 500;
  color: $blue;
  height: 40px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 8px;
    fill: $blue;
  }

  i:first-child {
    margin-right: 8px
  }

  i:last-child {
    margin-left: 8px
  }

  &.is--small {
    @include caption;
  }

  &:hover {
    color: $blue900;
  }
}
