@import "resources.scss";
.search-field {
  @include sidebar-search-box;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &-big {
    min-width: 350px;
  }

  &-inner {
    position: relative;
  }

  input {
    @extend %is-font-medium;
    border: 0;
    height: $input-height - 2px;
    outline: 0;
    padding-left: 15px;
    width: 100%;
    @include input-placeholder {
      color: $gray;
    }
  }

  &-icon-group {
    @include pos(center right 0px );
    font-size: 15px;
    background: white;
    i {
      padding: 0 10px;
    }
  }
  &-dark {
    input {
      background: $light-gray;
      color: $body;
      // @include input-placeholder {
      //   color: $body;
      // }
    }
    i {
      @include pos(center right 10px );
      font-size: 15px;
    }
  }

  &-category {
    max-height: 0;
    transition: all .2s;
    padding: 0 20px;

    &-open {
      padding: 20px;
      max-height: none;
      transition: all .2s;
    }
  }
}
